<template>
  <main class="container network--content">
    <router-view />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NetworkRoutes',
  mounted () {
    const routeGuard = this.$router.beforeEach((to, from, next) => {
      const unsavedWork = (from.name === 'ClientConfig' && this.getNetworkHasChanged)

      if (unsavedWork) {
        next(false)
        this['networkConfig/setShowNetworkAlertModal'](to.path)
      } else {
        next(true)
      }
    })

    this.$once('hook:destroyed', () => {
      routeGuard()
    })
  },
  computed: {
    ...mapGetters('networkConfig', [
      'getNetworkHasChanged'
    ])
  },
  methods: {
    ...mapActions(['networkConfig/setShowNetworkAlertModal', 'networkConfig/setNetworkHasChanged'])
  }
}
</script>

<style lang="scss" scoped>
.network--content {
  font-family: 'Centivo-Regular';
}
</style>
